import {types as baseTypes} from '@contentpeppergmbh/base';

// register each available plugin for this app
import {registerPlugin as registerDashboardPlugin} from '@contentpeppergmbh/plugin-dashboard';
import {registerPlugin as registerPluginAuth} from '@contentpeppergmbh/plugin-auth';
import {registerPlugin as registerPluginGlobalSearch} from '@contentpeppergmbh/plugin-global-search';
import {registerPlugin as registerPluginNotifications} from '@contentpeppergmbh/plugin-notifications';
import {registerPlugin as registerPluginLanguageSwitcher} from '@contentpeppergmbh/plugin-language-switcher';
import {registerPlugin as registerPluginProfileMenu} from '@contentpeppergmbh/plugin-profile-menu';
import {registerPlugin as registerPluginFooterMenu} from '@contentpeppergmbh/plugin-footer-menu';
import {registerPlugin as registerPluginChannels} from '@contentpeppergmbh/plugin-channels';
import {registerPlugin as registerPluginCleverreach} from '@contentpeppergmbh/plugin-cleverreach';
import {registerPlugin as registerPluginContentEditor} from '@contentpeppergmbh/plugin-content-editor';
import {registerPlugin as registerContentPlugin} from '@contentpeppergmbh/plugin-content';
import {registerPlugin as registerAnalyticsPlugin} from '@contentpeppergmbh/plugin-analytics';
import {registerPlugin as registerPluginTitleBanner} from '@contentpeppergmbh/plugin-title-banner';
import {registerPlugin as registerPluginFormFields} from '@contentpeppergmbh/plugin-form-fields';
import {registerPlugin as registerPluginMegadraft} from '@contentpeppergmbh/plugin-megadraft';
import {registerPlugin as registerPluginMegadraftLiteratureTip} from '@contentpeppergmbh/plugin-megadraft-literature-tip';
import {registerPlugin as registerPluginMegadraftSpecialQuote} from '@contentpeppergmbh/plugin-megadraft-special-quote';
import {registerPlugin as registerPluginMegadraftBusinessCard} from '@contentpeppergmbh/plugin-megadraft-business-card';
import {registerPlugin as registerPluginSeo} from '@contentpeppergmbh/plugin-seo';
import {registerPlugin as registerPluginStructure} from '@contentpeppergmbh/plugin-structure';
import {registerPlugin as registerPluginPlanningTool} from '@contentpeppergmbh/plugin-planning-tool';
import {registerPlugin as registerPluginIconPicker} from '@contentpeppergmbh/plugin-icon-picker';
import {registerPlugin as registerPluginUrlManagement} from '@contentpeppergmbh/plugin-url-management';
import {registerPlugin as registerPluginAssetManagement} from '@contentpeppergmbh/plugin-asset-management';

import {register as registerUi} from '@contentpeppergmbh/ui';

export const availablePlugins: baseTypes.PluginConfig[] = [
  registerUi(),
  registerDashboardPlugin(),
  registerAnalyticsPlugin(),
  registerPluginAuth(),
  registerPluginGlobalSearch(),
  registerPluginNotifications(),
  registerPluginLanguageSwitcher(),
  registerPluginProfileMenu(),
  registerPluginFooterMenu(),
  registerPluginChannels(),
  registerPluginContentEditor(),
  registerContentPlugin(),
  registerPluginTitleBanner(),
  registerPluginCleverreach(),
  registerPluginFormFields(),
  registerPluginMegadraft(),
  registerPluginMegadraftLiteratureTip(),
  registerPluginMegadraftSpecialQuote(),
  registerPluginMegadraftBusinessCard(),
  registerPluginSeo(),
  registerPluginStructure(),
  registerPluginPlanningTool(),
  registerPluginIconPicker(),
  registerPluginUrlManagement(),
  registerPluginAssetManagement()
];

// would be loaded from server side
export const activePlugins: string[] = [
  'cp-ui',
  'cp-dashboard',
  'cp-auth',
  'cp-global-search',
  'cp-language-switcher',
  'cp-notifications',
  'cp-profile-menu',
  'cp-footer-menu',
  'cp-channels',
  'cp-content-editor',
  'cp-content',
  'cp-title-banner',
  'cp-channel-fCMS',
  'cp-cleverreach',
  'cp-form-fields',
  'cp-megadraft',
  'cp-megadraft-literature-tip',
  'cp-megadraft-special-quote',
  'cp-megadraft-business-card',
  'cp-seo',
  'cp-structure',
  'cp-planning-tool',
  'cp-icon-picker',
  'cp-url-management',
  'cp-asset-management'
];
